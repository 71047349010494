import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
     <p> Soon: Machines in <b>your</b> pocket!</p>
      </header>
    </div>
  );
}

export default App;
